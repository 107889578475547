<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" :model="formData" label-width="100px">
      <el-form-item v-show="$route.query.showCategory" label="上级分类">
        <SelectGoodsCategory v-model="formData.parentId"></SelectGoodsCategory>
      </el-form-item>
      <el-form-item label="名称" prop="categoryName" :rules="rules">
        <el-input v-model="formData.categoryName" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item label="图标">
        <FileUpload :name.sync="formData.icon" :url="formData.iconUrl"></FileUpload>
      </el-form-item>
      <el-form-item label="排序" prop="sort" :rules="rules">
        <el-input v-model="formData.sort" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <!-- <el-form-item label="状态">
        <el-switch class="switch" v-model="value" active-value="1" inactive-value="2" active-text="显示" inactive-text="隐藏"></el-switch>
      </el-form-item> -->
      <el-form-item label="">
        <el-button type="primary" size="small" @click="save">保存</el-button>
        <el-button type="" size="small" @click="$router.back()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import SelectGoodsCategory from '@/views/components/select-goods-category.vue'
import FileUpload from '@/components/file-upload/index.vue'
import { addGoodsCategoryAPI, editGoodsCategoryAPI, getGoodsCategoryDetailAPI } from './api'
export default {
  name: 'AddOrEdit',
  components: { SelectGoodsCategory, FileUpload },
  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      formData: {
        parentId: 0,
        categoryName: '',
        icon: '',
        iconUrl: '',
        sort: 1
      },
      pid: 0,
      isShow: false
    }
  },

  mounted() {
    this.pid = this.$route.params.id
    if (this.$route.params.id && this.$route.params.parentId === undefined) this.getGoodsCategoryDetail()
  },

  methods: {
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.formData.parentId == null) this.formData.parentId = 0
          if (this.formData.id) {
            // console.log('编辑')
            // 编辑菜单
            editGoodsCategoryAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
          } else {
            // 新增分两种，$route.params中有id时为新增子级，无则为新增一级
            if (this.$route.params.id) {
              this.formData.parentId = this.$route.params.id
              addGoodsCategoryAPI(this.formData).then(() => {
                this.$message.success('新增成功')
                this.$router.back()
              })
              // console.log('新增子级')
            } else {
              addGoodsCategoryAPI(this.formData).then(() => {
                this.$message.success('新增成功')
                this.$router.back()
              })
              // console.log('新增一级')
            }
          }
        }
      })
    },
    async getGoodsCategoryDetail() {
      const res = await getGoodsCategoryDetailAPI(this.$route.params.id)
      this.pid = res.parentId
      this.formData = res
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .el-form {
    .el-form-item {
      .el-input {
        width: 500px;
      }
    }
  }
  /* switch按钮样式 */
  .switch .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }
  /*打开时文字位置设置*/
  .switch .el-switch__label--right {
    z-index: 1;
  }
  /* 调整打开时文字的显示位子 */
  .switch .el-switch__label--right span {
    margin-left: 5px;
  }
  /*关闭时文字位置设置*/
  .switch .el-switch__label--left {
    z-index: 1;
  }
  /* 调整关闭时文字的显示位子 */
  .switch .el-switch__label--left span {
    margin-left: 20px;
  }
  /*显示文字*/
  .switch .el-switch__label.is-active {
    display: block;
  }
  /* 调整按钮的宽度 */
  .switch.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 148px;
    height: 148px;
    line-height: 148px;
    text-align: center;
  }
  .avatar {
    width: 148px;
    height: 148px;
    display: block;
  }
}
</style>
