import { http } from '@/http/axios.js'
// 分类树
export function getGoodsCategoryTreeAPI(params) {
  return http({
    url: '/admin/goods/category/tree',
    method: 'get',
    params
  })
}
// 新增分类
export function addGoodsCategoryAPI(data) {
  return http({
    url: '/admin/goods/category/add',
    method: 'post',
    data
  })
}
// 编辑分类
export function editGoodsCategoryAPI(data) {
  return http({
    url: '/admin/goods/category/edit',
    method: 'post',
    data
  })
}
// 详情
export function getGoodsCategoryDetailAPI(id) {
  return http({
    url: '/admin/goods/category/detail',
    method: 'get',
    params: { id }
  })
}
// 状态
export function changeStatusAPI(data) {
  return http({
    url: '/admin/goods/category/status',
    method: 'post',
    data
  })
}
// 删除
export function deleteGoodsCategoryAPI(id) {
  return http({
    url: '/admin/goods/category/delete',
    method: 'post',
    data: { id }
  })
}
